import React from 'react'
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Seo from '../../components/SEO'

const MainEventPage = ({ data }) => {
  const allEvents = data.allSanityEvent.nodes
  const upcomingEvents = allEvents.filter(event => new Date(event.startDate) > new Date())
  const pastEvents = allEvents.filter(event => new Date(event.endDate) < new Date())

  return (
    <>
      <Seo title='Events' />
      <StyledMainEventPage>
        <h1>Events</h1>

        <h2>Upcoming Events</h2>
        <table>
          <thead>
            <tr>
              <th>Year</th>
              <th>Title</th>
              <th>Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Go to page</th>
            </tr>
          </thead>
          <tbody>
            {upcomingEvents.map(({ title, type, startDate, endDate, eventSeason, _id}) => (
              <tr key={_id}>
                <td>{eventSeason.year}</td>
                <td>{title}</td>
                <td>{type}</td>
                <td>{startDate}</td>
                <td>{endDate}</td>
                <td><Link to={`/events/${_id}/`}>Go to event page</Link></td>
              </tr>
            ))}
          </tbody>
        </table>

        <h2>Past Events</h2>
        <table>
          <thead>
            <tr>
              <th>Year</th>
              <th>Title</th>
              <th>Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Go to page</th>
            </tr>
          </thead>
          <tbody>
            {pastEvents.map(({ title, type, startDate, endDate, eventSeason, _id}) => (
              <tr key={_id}>
                <td>{eventSeason.year}</td>
                <td>{title}</td>
                <td>{type}</td>
                <td>{startDate}</td>
                <td>{endDate}</td>
                <td><Link to={`/events/${_id}/`}>Go to event page</Link></td>
              </tr>
            ))}
          </tbody>
        </table>
      </StyledMainEventPage>
    </>
  )
}

export const query = graphql`
  query MainEventPageQuery {
    allSanityEvent {
      nodes {
        title
        type
        startDate
        endDate
        eventSeason {
          year
        }
        _id
      }
    }
  }
`

const StyledMainEventPage = styled.main`
  table, th, td {
    border: solid 1px rgba(255, 2555, 255, 0.2);
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    padding: 0.2em 0.4em;
  }
`

export default MainEventPage